import { UtilService } from './../../services/util.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImagesService } from '../../services/images/images.service';
import { ImagenesFaltantesService } from '../../services/imagenes-faltantes/imagenes-faltantes.service';
import { ImagenesFaltantes } from '../imagenes-faltantes/model-imagenes-faltantes/model-imagenes-faltantes';
import { ModalImagenesFaltantesComponent } from './modal-imagenes-faltantes/modal-imagenes-faltantes.component';
import { environment } from '../../../environments/environment';
import { DateAdapter } from '@angular/material/core';
import { EmpresaService } from '../../services/Empresa/Empresa.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-imagenes-faltantes',
  templateUrl: './imagenes-faltantes.component.html',
  styleUrls: ['./imagenes-faltantes.component.scss']
})

export class ImagenesFaltantesComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  matTableDataSource: MatTableDataSource<ImagenesFaltantes>;
  public data = [];
  public formData = new FormData();
  public columns: string[] = [];
  public path: string =environment.apiImage + '/Kabik/Pagina/';
  public formGroup = this.formBuilder.group({
    empresa: environment.empresaId,
    clave: '',
    tiene_imagen: '',
    fecha_inicial: [new Date(), [Validators.required]],
    fecha_final: [new Date(), [Validators.required]]
  });

  public displayedColumns = [
    'imagen',
    'ClaveArticulo',
    'Descripcion',
    'FechaAlta',
    'EnEdicion',
    'TieneImagen',
    'ActualizoImagen',
    'FechaAltaImagen',
    'accion'
  ];
  empresaId =  String(environment.empresaId);
  private destroy$ = new Subject<void>();
  constructor(
    private imagesService: ImagesService,
    private imagenesFaltantesService: ImagenesFaltantesService,
    private router: Router,
    private _adapter: DateAdapter<any>,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    public matDialogUpdate: MatDialog,
    private utilService: UtilService,
    private empresaService: EmpresaService
  ) {
    this._adapter.setLocale('mx');
    this.empresaService.empresaId$
    .pipe(takeUntil(this.destroy$))
    .subscribe(id => {
      this.empresaId = id;
      this.formGroup.patchValue({ empresa: this.empresaId });
      const newRuta =  id === '1' || id === 1 ?'/Kabik':'/Letech';
      this.path = environment.apiImage +newRuta+'/imagenes_fichas/';
    });
  }

  ngOnInit() {
    this.spinner.show('spinner');
    setTimeout(() => {
      this.spinner.hide('spinner');
    }, 500);
  }

  exportToExcel(): void {
    this.spinner.show('spinner');
    this.imagenesFaltantesService.exportToExcel(this.data, this.columns).then((res) => {
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }

  public exportFilterToExcel = () => {
    this.spinner.show('spinner');
    let data = this.matTableDataSource.filteredData;
    this.imagenesFaltantesService.exportToExcel(data, this.columns).then((res) => {
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }

  replace(data: string) {
    return this.utilService.replaceAllByArrayString(["/", "#", "%", "?", "[", "]", "'", " ", "<", ">"], data, "_");
  }

  filter(event: Event) {
    const filter = (event.target as HTMLInputElement).value;
    this.matTableDataSource.filter = filter.trim().toLowerCase();
    if (this.matTableDataSource.paginator) {
      this.matTableDataSource.paginator.firstPage();
    }
  }

  onSubmit() {
    if (this.formGroup.invalid || this.formGroup.value.fecha_inicial == null || this.formGroup.value.fecha_final == null || (this.formGroup.value.fecha_inicial > this.formGroup.value.fecha_final)) {
      Swal.fire({
        type: 'warning',
        title: 'Fechas incorrectas',
        text: 'Por favor, verifique las fechas',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    this.formData.append('empresa', this.empresaId);
    this.formData.append('clave', this.formGroup.value.clave);
    this.formData.append('tiene_imagen', this.formGroup.value.tiene_imagen);
    this.formData.append('fecha_inicial', new Date(this.formGroup.value.fecha_inicial).toISOString().slice(0, 10));
    this.formData.append('fecha_final', new Date(this.formGroup.value.fecha_final).toISOString().slice(0, 10));

    this.show();
  }

  show() {
    this.spinner.show('spinner');
    this.formData.append('opcion', 'show');
    this.imagenesFaltantesService.show(this.formData).then((res) => {
      if (res.message == 'true') {
        this.matTableDataSource = new MatTableDataSource(res.response);
        this.matTableDataSource.sort = this.sort;
        this.matTableDataSource.paginator = this.paginator;
        this.columns = res.columns;
        this.data = res.response;
      }
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message
      });
      this.spinner.hide('spinner');
    });
  }

  public openModalUpdate(data: any) {
    this.imagesService.getImagenEnEdicion(data.ClaveArticulo).subscribe((res) => {
      if (!res) {
        const modal = this.matDialogUpdate.open(ModalImagenesFaltantesComponent, {
          width: '35%',
          data: data,
          disableClose: true
        });
        this.imagesService.setImagenEnEdicion(data.ClaveArticulo, 'SI').subscribe((res) => { });
        modal.afterClosed().subscribe((res) => {
          if (res) {
            this.imagesService.setImagenEnEdicion(data.ClaveArticulo, 'NO').subscribe((res) => { });
            this.show();
          }
        });
      } else {
        Swal.fire({
          type: 'info',
          title: 'Información',
          text: 'La imagen ya se está editando por otra persona'
        });
      }
    });
  }

  enEdicion(imagen: any) {
    this.imagesService.setImagenEnEdicion(imagen.ClaveArticulo, 'NO').subscribe((res) => {
      if (!res) {
        Swal.fire({
          type: 'info',
          title: 'Error',
          text: 'Lo sentimos, ocurrió un error, comprueba tu conexión a internet e intenta de nuevo, si perciste el error contacta al administrador'
        });
      } else {
        imagen.EnEdicion = 'NO';
      }
    });
  }

  public today = (): Date => {
    return new Date();
  };
}
