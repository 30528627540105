
//export const apiUrlEstafetaProd = 'https://api.kabik.mx/paqueteria/Estafeta/';
//export const apiUrlEstafeta = 'http://localhost/paqueteria/';
export const apiUrlEstafeta = 'https://api.kabik.mx/paqueteria/Estafeta/';
export const paqueteriaEstafeta = 12;
export const environment = {
  production: true,
   apiUrl: 'https://api.kabik.mx/',
  empresaId: 1,
  apiUrlTest: 'https://api.kabik.mx/',
  apiImage: 'https://storagemultimedia.net/imagenes_pagina/',
  version: '1.0.26',
  WSPaqueteria: `${apiUrlEstafeta}WSPaqueteria/WSPaqueteria.php`,
  WSEstafeta: `${apiUrlEstafeta}WSEstafeta/WSEstafeta.php`,
  WSGeneric: `${apiUrlEstafeta}WSPaqueteria/WSPaqueteria.php/generic-querie`,
  WSPaqueteriaCatTipoCamino: `${apiUrlEstafeta}WSPaqueteriaCatTipoCamino/WSPaqueteriaCatTipoCamino.php`,
  WSPaqueteriaCatPais: `${apiUrlEstafeta}WSPaqueteriaCatPais/WSPaqueteriaCatPais.php`,
  WSPaqueteriaCatEstado: `${apiUrlEstafeta}WSPaqueteriaCatEstado/WSPaqueteriaCatEstado.php`,
  WSPaqWSPaqueteriaCatCiudad: `${apiUrlEstafeta}WSPaqueteriaCatCiudad/WSPaqueteriaCatCiudad.php`,
  WSPaqueteriaCatAsentamiento: `${apiUrlEstafeta}WSPaqueteriaCatAsentamiento/WSPaqueteriaCatAsentamiento.php`,
  //WSEstafetaLocal: `${apiUrlEstafetaLocal}WSEstafeta/WSEstafeta.php`,
};
